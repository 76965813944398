import React from 'react';
import { Redirect } from 'react-router-dom';
import { faBan, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import State from 'components/State';
import { useContext } from 'context';
import WalletLogin from './Login/Wallet';

const Home = () => {
  const { loading, error, loggedIn, egldLabel } = useContext();

  const ref = React.useRef(null);

  return (
    <div ref={ref} className="home d-flex flex-fill align-items-center">
      {error ? (
        <State
          icon={faBan}
          iconClass="text-primary"
          title="Something went wrong"
          description="If the problem persists please contact support."
        />
      ) : loggedIn ? (
        <Redirect to="/dashboard" />
      ) : loading ? (
        <State icon={faCircleNotch} iconClass="fa-spin text-primary" />
      ) : (
        <div className="m-auto login-container">
          <div className="card my-spacer text-center">
          <div className="card-body p-spacer my-2 mx-lg-2">
              <Logo className="logo mb-4" />
              <h4 className="mb-2">Delegation Manager</h4>
              <p className="lead mb-spacer">Manage your Alchemy Capital stakes and rewards.</p>
              <div>
                <a href="/ledger" className="btn btn-primary px-sm-spacer mx-1 mx-sm-3">
                  Ledger
                </a>
                <WalletLogin />
              </div>
            </div>
          </div>
          <div className="text-center">
            <u>
              <a href="https://alchemy.capital/">Back to main site</a>
            </u>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
